export enum ChainId {
  Ethereum = 1,
  // Goerli = 5,
  Gnosis = 100,
  Polygon = 137,
  PolygonAmoy = 80002,
  Arbitrum = 42161,
  ArbitrumGoerli = 421613,
  Linea = 59144,
  LineaGoerli = 59140,
  Chiliz = 88888,
  ChilizSpicy = 88882,
}


export enum LiquidityTabs {
  Pools = 'pools',
  Positions = 'positions'
}

export enum PointsLevelName {
  Grey = 'Grey',
  Mist = 'Mist',
  Sky = 'Sky',
  Blue = 'Blue',
  Ultramarine = 'Ultramarine',
  Bright = 'Bright',
  Brilliant = 'Brilliant',
  Royal = 'Royal',
}
